.ant-drawer-header {
    background-color: #397551;
}

.ant-drawer-header-title svg,
.ant-drawer-title {
    color: #fff !important;
}

.ant-pro-sider-logo-collapsed .customLogo {
    width: auto;
    /* max-width: 169px; */
    height: 22px !important;
}
.customLogo {
    width: 100%;
    /* max-width: 169px; */
    height: 110px !important;
}
